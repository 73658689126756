import Vue from 'vue'
import Vuex from 'vuex'
import cart from '@/store/cart.store'
import cookieConsent from '@/store/cookies.store'
import config from "@/store/config.store"
import reservation from "@/store/reservation";
import loading from "@/store/loading.store"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {reservation, cart, cookieConsent, config, loading}
})
