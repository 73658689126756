import {DateTime} from "luxon";

export function arrayQueryString(array) {
    let res = ''
    array.forEach((elem, idx) => {
        if (idx !== 0)
            res += ','
        res += elem
    })
    return res
}

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(str) {
    return str && typeof str === "string" && isoDateFormat.test(str);
}

export function handleDates(data) {
    if (data === null || data === undefined || typeof data !== "object")
        return data;
    for (const key of Object.keys(data)) {
        const value = data[key];
        if (isIsoDateString(value))
            data[key] = DateTime.fromISO(value);
        else if (typeof value === "object") handleDates(value);
    }
}
