<template>
  <cld-image :public-id="publicId"
             crop="scale"
             :width="width"
             :height="height"
             :alt="publicId">
    <cld-transformation quality="auto:low"/>
  </cld-image>
</template>

<script>
export default {
  props: {
    publicId: String,
    width: {
      type: String,
      default: '300'
    },
    height: {
      type: String,
      default: 'auto'
    }
  }
}
</script>
