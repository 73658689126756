<template>
  <div id="app" class="position-absolute h-100 w-100 pb-5">
    <keep-alive exclude="*">
      <router-view/>
    </keep-alive>
    <cookie-consent/>
    <LoadingOverlay/>
  </div>
</template>

<script>
import CookieConsent from "@/components/generic/CookieConsent";
import LoadingOverlay from "@/components/generic/LoadingOverlay";
import {mapGetters} from "vuex";
import {bootstrap} from 'vue-gtag'

export default {
  components: {LoadingOverlay, CookieConsent},
  computed: {
    ...mapGetters(['trackingConsent'])
  },
  watch: {
    trackingConsent: {
      immediate: true,
      handler(consent) {
        if (consent) {
          bootstrap().then(() => {
            console.log('Google Analytics tracking turned on.')
          })
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('init', {}, {root: true})
        .catch(err => this.handleError(err))
  }
}
</script>


<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>

