import {DateTime} from "luxon";
import api from "@/spaboot-api";

const state = {
    state: {
        reservation: undefined,
        customer: {}
    },
    mutations: {
        updateReservation(state, reservation) {
            state.reservation = reservation
        },
        reset(state) {
            state.reservation = undefined
        }
    },
    actions: {
        async loadReservation({commit}, number) {
            let reservation = await api.get(`/reservations/${number}`)
                .then(response => response.data)
            await enrichReservationWithPrice(reservation)
            commit('updateReservation', reservation)
            return reservation
        },
        async changeReservation({dispatch, state}){
            await api.put(`/reservations/${state.reservation.number}`, state.reservation)
                .then(response => response.data)
            dispatch('loadReservation', state.reservation.number)
        },
        confirmReservation({commit, state}) {
            return api.post('/reservations',
                {
                    ...state.reservation,
                    startTime_: state.reservation.startTime.toFormat("yyyy-MM-dd'T'HH:mm:ss")
                },
                {
                    params: {
                        send: true
                    }
                }).then(response => {
                let reservation = response.data
                reservation.startTime = DateTime.fromISO(reservation.startTime)
                commit('updateReservation', reservation)
            })
        },
        cancelReservation({commit, state}) {
            return api.delete(`/reservations/${state.reservation.number}`)
                .then(() => commit('reset'))
        }
    },
    getters: {
        reservation(state) {
            return state.reservation
        },
        reservationDefined(state) {
            let reservation = state.reservation
            return reservation
                && reservation.startTime?.hour > 0
                && reservation.duration > 0
                && reservation.attendees > 0
                && reservation.product?.id
                && reservation.state !== 'canceled' && reservation.state !== 'expired';
        },
        reservationConfirmed(state) {
            return state.reservation?.number
        }
    },
}

const enrichReservationWithPrice = async function (res) {
    let reservation = res;
    reservation.startTime = DateTime.fromISO(reservation.startTime)
    if (!reservation?.product?.id)
        reservation.price = 0
    else
        reservation.price = await api.get('/prices', {
            params: {
                product: reservation.product.id,
                attendees: reservation.attendees,
                startDate: reservation.startTime.toISODate()
            }
        }).then(response => {
            return response.data.amount
        })
    return reservation
}

export default state

