import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import '@/assets/styles/custom.scss';

import Toast, {POSITION} from 'vue-toastification'

Vue.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 10000
});

import '@/plugins/fontawesome'

import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
    componentPrefix: 'vc',
    locale: 'de-DE',
    timezone: 'Europe/Berlin'
});

// Google Analytics
import VueGtag from "vue-gtag";

const streamId = (process.env.NODE_ENV === 'production') ? "G-RYY0XDKXSJ" : "G-ZDB086P9PY"
Vue.use(VueGtag, {
    bootstrap: false,
    config: {
        id: streamId
    }
}, router)

// Form validation
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import '@/validation.js'

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import spabootApi from './spaboot-api'
import StandardLayout from "@/layouts/StandardLayout";

Vue.prototype.$api = spabootApi

Vue.config.productionTip = false

Vue.filter('price', function (p, includeFraction) {
    let res = ''
    if (!p)
        res += includeFraction ? '0.00' : '0';
    else
        res += p.toFixed(includeFraction ? 2 : 0).toLocaleString()
    return res + '€'
});

Vue.filter('quantityUnit', function (p) {
    if (p.pricing === 'byHour')
        return 'h'
    return 'x'
});

import {Settings, DateTime} from 'luxon'

Settings.defaultZoneName = "Europe/Berlin";
DateTime.prototype.toISO = function () {
    return this.toFormat("yyyy-MM-dd'T'HH:mm:ss")
}

Vue.filter('dateTime', function (d) {
    if (!d)
        return '';
    let dt = DateTime.fromJSDate(new Date(d)).setLocale('de')
    return dt.weekdayShort + ', ' + dt.toFormat('d.M.yy HH:mm')
});


import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo)

// vue-debounce
// import vueDebounce from 'vue-debounce'
// Vue.use(vueDebounce)

Vue.filter('date', function (d) {
    if (!d)
        return '';
    let dt = DateTime.fromJSDate(new Date(d)).setLocale('de')
    return dt.toFormat('EEE, d.M.y')
});

// Cloudinary
import Cloudinary from 'cloudinary-vue';

Vue.use(Cloudinary, {
    configuration: {
        cloudName: "spaboot"
    }
});

Vue.component('standard-layout', StandardLayout)

import MessagesMixin from "@/mixins/MessagesMixin";

Vue.mixin(MessagesMixin)

import {mixin} from 'vue-clickaway'

Vue.mixin(mixin)

import cloudinaryBackgroundImageUrlMixin from '@/mixins/CloudinaryBackgroundImageUrl'

Vue.mixin(cloudinaryBackgroundImageUrlMixin)

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    dsn: "https://0e5c95c07e134331884883f47335a991@o1075478.ingest.sentry.io/6076152",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "my-site-url.com", /^\//],
            logErrors: true
        }),
    ],
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
        const error = hint.originalException;
        if (error) {
            console.log(error.stack)
        }
        if (!store.getters.technicalConsent) {
            return null;
        }
        return event;
    }
});

import VueMq from 'vue-mq'
Vue.use(VueMq, {
    breakpoints: {
        sm: 767,
        md: 992,
        lg: Infinity
    }
})

Vue.config.devtools = true
Vue.config.debug = true

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
