<template>
    <div class="position-relative d-flex justify-content-center w-100 border border-secondary bg-white p-2 rounded"
         style="max-height: 46px; max-width: 200px;"
         v-if="rating >= 4.5">
        <div class="d-flex">
            <cloudinary-image public-id="home/g"
                              height="24" width="24"/>
        </div>
        <div class="d-flex pl-1 small">
            <div v-for="star in [1, 2, 3, 4, 5]"
                 :key="`star-${star}`">
                <Star v-if="rating >= star" :half="rating > star && rating < star + 1" :width="10" :height="10"/>
            </div>
        </div>
        <div class="d-flex pl-1 small">
            <span class="rating-text">{{ rating.toFixed(1) }}</span>
            &nbsp;({{ reviewCount }})
        </div>
    </div>
</template>

<script>
import CloudinaryImage from "../generic/CloudinaryImage.vue";
import Star from "./Star.vue";

export default {
    components: {Star, CloudinaryImage},
    data() {
        return {
            rating: -1,
            reviewCount: 0
        }
    },
    async mounted() {
        const response = await this.$api.get('/reviews/rating')
            .then(d => d.data)
            .catch(err => this.handleError(err))
        this.rating = response.rating
        this.reviewCount = response.reviewCount
    }
}
</script>

<style scoped>
.rating-text {
    font-weight: bold;
}

</style>
