<template>
    <font-awesome-icon :icon="half ? 'star-half' : 'star'" class="star" :height="height" :width="width"/>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 20
        },
        height: {
            type: Number,
            default: 20
        },
        half: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.star {
    color: #F8B805;
}
</style>
