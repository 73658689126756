<template>
  <textblock class="bg-white border rounded border-secondary">
    <template #header>Die Geschenkidee!</template>
    <template #main>
      <div class="row">
        <div class="col-12 col-md-5 mt-md-2" @click="$router.push({name: 'purchaseVoucher'})">
          <cloudinary-image
              public-id="gutscheine"
              class="img-fluid"
              width="640"
              height="429"
              crop="scale">
            <cld-transformation quality="auto:low"/>
          </cloudinary-image>
        </div>
        <div class="col-12 col-md-7 my-md-auto">
          <div class="mb-3">Jetzt einen SPABoot-Gutschein verschenken. Ideal für Freundesrunden und romantische
            Paare.<br/>
          </div>
          <c-t-a-button route="purchaseVoucher">
            Gutschein verschenken
          </c-t-a-button>
        </div>
      </div>
    </template>
  </textblock>
</template>

<script>
import Textblock from '@/components/generic/Textblock'
import CloudinaryImage from "@/components/generic/CloudinaryImage";
import CTAButton from "@/components/generic/CTAButton";

export default {
  components: {
    CTAButton,
    CloudinaryImage,
    Textblock
  }
}
</script>
