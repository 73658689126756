<template>
  <div>
    <div v-if="condition">
      <Navigation class="sticky-top"/>
      <div
          v-if="$slots.heading"
          class="container mt-md-2 mb-3 text-center">
        <slot name="heading"></slot>
      </div>
      <div id="main" class="container" :class="{'p-0': !padding}">
        <slot name="main"></slot>
      </div>
      <Footer class="sticky-bottom"/>
    </div>
    <loading-overlay/>
  </div>
</template>

<script>
import Navigation from "@/components/generic/Navigation";
import Footer from "@/components/generic/Footer";
import LoadingOverlay from "@/components/generic/LoadingOverlay";
import {mapActions} from "vuex";

export default {
  props: {
    condition: {
      type: Boolean,
      default: true
    },
    padding: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading'])
  },
  watch: {
    condition() {
      if (!this.condition)
        this.startLoading("")
      else
        this.stopLoading()
    }
  },
  components: {Footer, Navigation, LoadingOverlay},
}
</script>

<style scoped>
.sticky-bottom {
  position: sticky;
  bottom: 0;
}

#main {
  padding-bottom: 5rem;
  min-height: 90vh;
}
</style>
