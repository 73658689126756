<template>
  <div v-on-clickaway="collapse">
    <nav class="navbar navbar-expand-lg navbar-light border-bottom border-muted">

      <router-link to="/" class="navbar-brand text-muted">
        <cld-image public-id="logo.svg" width="131" height="24" alt="SPABoot Homepage"/>
      </router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" ref="collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

          <li class="nav-item">
            <router-link class="nav-link navbar-collapse"
                         :class="{'active': $route.meta.category === 'reservation'}"
                         :to="{name: 'editReservation'}">
              Reservieren
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link navbar-collapse"
                         :class="{'active': $route.meta.category === 'product'}"
                         :to="{name: 'productsAndPrices'}">
              Erlebnisse
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link navbar-collapse"
                         :class="{'active': $route.meta.category === 'voucher'}"
                         :to="{name: 'purchaseVoucher'}">
              Gutscheine
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link navbar-collapse"
                         :class="{'active': $route.meta.category === 'gallery'}"
                         :to="{name: 'gallery', params: {back: $route.name}}">
              Galerie
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link navbar-collapse"
                         :class="{'active': $route.meta.category === 'info'}"
                         :to="{name: 'info'}">
              Wissenswertes
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link navbar-collapse"
                         :class="{'active': $route.meta.category === 'about'}"
                         :to="{name: 'about'}">
              Über uns
            </router-link>
          </li>

        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  methods: {
    collapse() {
      this.$refs['collapse'].classList.remove('show')
    }
  }
}
</script>
<style>
.navbar {
  background-color: var(--ivory);
}
</style>
