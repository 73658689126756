import api from "@/spaboot-api";

const state = {
    state: {
        cart: undefined
    },
    mutations: {
        SET_CART(state, cart) {
            if (!cart?.customer)
                cart.customer = {}
            state.cart = {
                ...cart,
                lastModified: Date.now().valueOf()
            }
        }
    },
    actions: {
        initCartWithReservation({commit}, reservation) {
            return api.post('/carts', reservation)
                .then(response => {
                    commit('SET_CART', response.data)
                    return response.data
                })
        },
        async resetCart({dispatch, state, commit}){
            if(!state.cart)
                return dispatch('initCart')
            return await api.post(`/carts/${state.cart.id}/reset`)
                .then(response => {
                    commit('SET_CART', response.data)
                    return state.cart
                })
        },
        async initCart({commit, dispatch, state}, cartId) {
            if (cartId)
                return await api.get(`/carts/${cartId}`).then(response => {
                    commit('SET_CART', response.data)
                    if(response.data.reservationNumber)
                        dispatch('loadReservation', response.data.reservationNumber)
                    return state.cart
                }).catch(() => {
                    commit('SET_CART', undefined)
                    return dispatch('initCart')
                })
            else
                return  await api.post('/carts').then(response => {
                    let cart = response.data
                    commit('SET_CART', cart)
                    return state.cart
                })
        },
        async assignCustomerToCart({commit, state, dispatch, getters}, customer) {
            if (getters.cartImmutable)
                await dispatch('initCart')
            return api.post(`/carts/${state.cart.id}/customer`, customer)
                .then(response => commit('SET_CART', response.data))

        },
        async addArticleToCart({commit, state, dispatch, getters}, {article, quantity, startTime, attendees, priceAdd}) {
            if (getters.cartImmutable)
                await dispatch('initCart')
            return api.post(`/carts/${state.cart.id}/positions`, {
                article,
                quantity,
                startTime,
                attendees,
                priceAdd
            }).then(response => {
                commit('SET_CART', response.data)
            })
        },
        async updateCartPosition({commit, state, dispatch, getters}, {article, quantityAdjustment, startTime}) {
            console.log(article, quantityAdjustment, startTime)
            let quantityAdj = quantityAdjustment
            if(article.product.pricingType === 'byAttendees'){
                // pricingType 'byAttendees' requires a quantity of 0 or #attendees
                quantityAdj = state.cart.attendees * Math.sign(quantityAdj)
            }
            if (getters.cartImmutable)
                await dispatch('initCart')
            let pos = state.cart.positions.find(cp => cp.article.id === article.id)
            if (!pos)
                pos = {
                    article: article,
                    quantity: 0,
                    startTime: startTime
                }
            return api.post(`/carts/${state.cart.id}/positions`, {
                ...pos,
                quantity: pos.quantity + quantityAdj,
            }).then(response => {
                commit('SET_CART', response.data)
            }).catch(error => this.handleError(error))
        },
        updateCart({commit}, cart) {
            commit('SET_CART', cart)
        },
        async addVoucherToCart({dispatch, state, getters}, voucherCode) {
            if (getters.cartImmutable)
                await dispatch('initCart')
            await api.post(`/carts/${state.cart.id}/voucher/${voucherCode}`, {
                code: voucherCode
            }).then(response => dispatch('updateCart', response.data))
        },
        async removeVoucherFromCart({dispatch, state}, voucherCode) {
            await api.delete(`/carts/${state.cart.id}/voucher/${voucherCode}`)
                .then(response => dispatch('updateCart', response.data))
        },
        async updateAttendees({dispatch, state}, attendees) {
            return api.post(`/carts/${state.cart.id}/attendees`, {attendees})
                .then(response => dispatch('updateCart', response.data))
        }
    },
    getters: {
        cart(state) {
            return state.cart
        },
        reservationPrice(state) {
            let res = 0;
            state.cart.positions
                .filter(cp => ['RES', 'PER'].indexOf(cp.article.product.category) >= 0)
                .forEach(cp => res += cp.totalGrossPrice)
            return res
        },
        cartImmutable(state){
            if(!state.cart)
                return true
            return state.cart.state === 'CHECKED_OUT'
        }
    }
}

export default state
