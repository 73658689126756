<script>
import cloudinary from 'cloudinary-core'
const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: 'spaboot'});

export default {
  methods: {
    backgroundImageUrl(publicId){
      // const width = 100%;
      const t = new cloudinary.Transformation();
      // t.crop('scale').width(width).aspectRatio('1:1');
      return cloudinaryCore.url(publicId, t);
    }
  }
}
</script>