<template>
    <div class="container">
        <div class="row d-sm-block d-md-none">
            <div class="col-12 col-md-4 ">
                <review-widget :review="currentReview" class="review-widget"/>
            </div>
        </div>
        <div class="row d-none d-md-flex">
            <div class="col-4" v-for="r in currentReviews" :key="r.authorUrl">
                <review-widget :review="r" class="review-widget"/>
            </div>
        </div>
    </div>
</template>

<script>
import {DateTime} from "luxon";
import ReviewWidget from '@/components/reviews/ReviewWidget'

export default {
    components: {
        ReviewWidget
    },
    data() {
        return {
            reviews: [],
            reviewIndex: 0,
            timer: null
        }
    },
    computed: {
        currentReview() {
            if (this.reviews.length > 0)
                return this.reviews[this.reviewIndex]
            return null
        },
        currentReviews() {
            if (this.reviews.length === 0)
                return []
            const idx = this.reviewIndex * 3 % this.reviews.length
            return [this.reviews[idx],
                this.reviews[(idx + 1) % this.reviews.length],
                this.reviews[(idx + 2) % this.reviews.length]]
        }
    },
    methods: {
        next() {
            if (this.reviews.length > 0)
                this.reviewIndex = (this.reviewIndex + 1) % this.reviews.length
        }
    },
    created() {
        this.$api.get('/reviews')
            .then(res => {
                const reviews = []
                for (let review of res.data) {
                    review.time = DateTime.fromFormat(review.time, "dd.MM.yyyy HH:mm").toFormat('MMMM yy', {locale: navigator.language})
                    reviews.push(review)
                }
                this.reviews = reviews;
            })
            .catch(err => this.handleError(err))
        this.timer = setInterval(this.next, 7000)
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
.review-widget {
    margin: 10px;
    font-size: smaller;
}
</style>
