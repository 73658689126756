import Vue from 'vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faArrowAltCircleRight,
    faArrowAltCircleLeft,
    faPlusCircle,
    faMinusCircle,
    faInfoCircle,
    faHome,
    faEdit,
    faMobileAlt,
    faUserEdit,
    faSpinner,
    faCheckCircle,
    faVirus,
    faTimes,
    faSnowflake,
    faEnvelope, faStar, faStarHalf
} from '@fortawesome/free-solid-svg-icons'

import {
    faInstagram,
    faFacebook
} from '@fortawesome/free-brands-svg-icons'

library.add(faArrowAltCircleLeft)
library.add(faArrowAltCircleRight)
library.add(faPlusCircle)
library.add(faMinusCircle)
library.add(faInfoCircle)
library.add(faHome)
library.add(faEdit)
library.add(faMobileAlt)
library.add(faUserEdit)
library.add(faSpinner)
library.add(faCheckCircle)
library.add(faVirus)
library.add(faTimes)
library.add(faSnowflake)
library.add(faInstagram)
library.add(faFacebook)
library.add(faEnvelope)
library.add(faStar)
library.add(faStarHalf)

Vue.component('font-awesome-icon', FontAwesomeIcon)
