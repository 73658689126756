import {extend} from 'vee-validate';
import {required, email} from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} darf nicht leer sein'
})

extend('email', {
    ...email,
    message: '{_field_} ist keine gültige Email-Adresse'
})

extend('zip', s => {
    if (s.length === 5 && !isNaN(s))
        return true
    return 'Die Postleitzahl ist nicht korrekt';
})

extend('phone', s => {
    return (!s || RegExp('^[+]?[ 0-9]*$').test(s)) ||
        'Die Telefonnummer hat ein ungültiges Format';
})

extend('accepted', s => {
    return s || 'Bitte akzeptiere die {_field_}'
})

