<template>
  <div class="widget-wrapper" v-if="review">
    <div class="widget-header">
      <div class="widget-header-content">
        <div class="widget-profile-img">
          <img :src="review.profilePhotoUrl" referrerpolicy="no-referrer" width="40" height="40"/>
        </div>
        <div class="authorName">
          {{ review.authorName }}
        </div>
        <div class="rating">
            <span v-for="index in review.rating" :key="index">
                <Star/>
            </span>
        </div>
        <div class="review-time">
          {{ review.time }}
        </div>
      </div>
    </div>
    <div class="review-text overflow-hidden text-muted">
      "{{ review.text }}"
    </div>
    <div class="widget-footer">
      <a target="_new" :href="review.authorUrl">weiterlesen auf <img class="img-fluid d-inline-flex g-img"
                                                                     :src="require('@/assets/img/g.png')"/></a>
    </div>
  </div>
</template>

<script>
import Star from "./Star.vue";

export default {
    components: {Star},
  props: {
    review: Object
  }
}
</script>

<style scoped>
.widget-wrapper {
  font-family: arial, serif;
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
}

.widget-header {
  margin-top: 12px;
}

.widget-profile-img {
  margin-right: 6px;
}

.widget-header-content {
  padding: 0 10px;
  text-align: center;
}

.authorName {
  align-self: center;
  margin-right: 6px;
}

.rating {
}

.review-time {
  font-size: smaller;
  color: grey;
  margin-bottom: 12px;
}

.review-text {
  position: relative;
  display: block;
  padding: 12px;
  max-height: 150px;
  height: 150px;
  text-align: center;
  font-style: italic;
  font-weight: lighter;
}

.review-text:after {
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  height: 40%;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(139,167,32,0) 0%, rgba(255,255,255,1) 100%);
  pointer-events: none;
}
.widget-footer {
  display: block;
  text-align: center;
  font-size: x-small;
}

.g-img {
  height: 14px;
}

</style>
