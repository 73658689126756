import api from '@/spaboot-api'
import {DateTime} from "luxon";

const SUMMER_SEASON_START = 1;
const WINTER_SEASON_START = 10;

const state = {
    state: {
        configuration: {}
    },
    mutations: {
        configuration(state, config) {
            state.configuration = config
        }
    },
    actions: {
        async init({commit}) {
            const config = await api.get('/configuration')
                .then(response => response.data)
            commit('configuration', config)
        }
    },
    getters: {
        configuration(state) {
            return state.configuration
        },
        isSummerSeason() {
            const date = DateTime.now();
            return date.month >= SUMMER_SEASON_START && date.month < WINTER_SEASON_START
        }
    }
}

export default state
