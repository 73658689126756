import axios from 'axios'
import {handleDates} from '@/utils/axios-utils'

const instance = axios.create({
    baseURL: process.env.VUE_APP_SPABOOT_API_URL
});

instance.setAuthtoken = function (authtoken) {
    instance.defaults.headers.common['X-SPABoot-Access-Token'] = authtoken
};

instance.interceptors.response.use(originalResponse => {
    handleDates(originalResponse.data);
    return originalResponse;
});

export default instance;


