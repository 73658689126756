import { render, staticRenderFns } from "./ReviewWidget.vue?vue&type=template&id=3085d57b&scoped=true&"
import script from "./ReviewWidget.vue?vue&type=script&lang=js&"
export * from "./ReviewWidget.vue?vue&type=script&lang=js&"
import style0 from "./ReviewWidget.vue?vue&type=style&index=0&id=3085d57b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3085d57b",
  null
  
)

export default component.exports