<template>
  <div class="bg-secondary small">
    <div class="footer pt-1 text-center">
      <div class="d-flex justify-content-center">
        <div>
          <router-link :to="{name: 'imprint'}">
            Impressum
          </router-link>
        </div>
        <div class="ml-2 ml-md-3">
          <router-link :to="{name: 'dataPrivacy'}">Datenschutz</router-link>
        </div>
        <div class="ml-2 ml-md-3">
          <AGBLink/>
        </div>
        <div class="ml-2 ml-md-3 text-light">| &nbsp;
          <router-link :to="{name: 'contact'}"><font-awesome-icon icon="envelope"/></router-link>
          <a href="https://www.facebook.com/spabootsauna" target="_blank" class="ml-2"><font-awesome-icon :icon="['fab', 'facebook']"/></a>
          <a href="https://instagram.com/spaboot_sauna" target="_blank" class="ml-2"><font-awesome-icon :icon="['fab', 'instagram']"/></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AGBLink from "@/components/AGBLink";

export default {
  components: {AGBLink},
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/custom";

.footer {
  padding-bottom: 0.3rem;
  width: 100%;
  position: relative;
  bottom: 0;
}

a {
  color: $ivory;
}

a:hover {
  color: $ivory;
  font-weight: bolder;
  text-decoration: none;
}

.router-link-active {
  font-weight: bolder;
}
</style>
