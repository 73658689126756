<template>
  <div class="overlay small" v-if="!consent.consent">
    <div class="cookie-consent">
      <slot name="banner" v-if="!customizing">
        <div class="row">
          <div class="col-md-8">
            Diese Website verwendet Cookies - nähere Information dazu und zu Deinen Rechten als Benutzer
            findest Du in unserer
            <router-link :to="{name: 'dataPrivacy'}">Datenschutzerklärung</router-link>
            .
            Klicke auf "Alle annehmen", um Cookies zu akzeptieren und direkt unsere Website benutzen zu können, oder
            klicke hier auf <span class="link btn-link" @click="customizing = true">Cookie Einstellungen</span>, um
            Deine Cookies
            selbst zu verwalten.
          </div>
          <div class="col-md-4 mt-2 text-nowrap">
            <button class="btn btn-primary" @click="acceptAll">Alle annehmen</button>
          </div>
        </div>
      </slot>
      <slot name="customize" v-else>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitch1"
                 v-model="technical">
          <label class="custom-control-label" for="customSwitch1">
            Für die anonymisierte Analyse von Problemen auf der Website Daten
            speichern
          </label>
        </div>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="tracking">
          <label class="custom-control-label" for="customSwitch2">
            Für die anonymisierte Analyse des Nutzerverhalten notwendige Daten
            speichern
          </label>
        </div>
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="social">
          <label class="custom-control-label" for="customSwitch3">
            Social Media auf der Website ermöglichen
          </label>
        </div>
        <div class="mt-2">
          <button class="btn btn-sm btn-primary mr-1" @click="consentGiven">Auswahl bestätigen</button>
          <button class="btn btn-sm btn-primary" @click="acceptAll">Alle annehmen</button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'

export default {
  data() {
    return {
      customizing: false,
      technical: false,
      tracking: false,
      social: false
    }
  },
  computed: {
    ...mapGetters(['consent'])
  },
  methods: {
    ...mapMutations(['SET_CONSENT']),
    consentGiven() {
      this.SET_CONSENT({
        consent: true,
        technical: this.technical,
        tracking: this.tracking,
        social: this.social
      })
    },
    acceptAll() {
      this.technical = true
      this.tracking = true
      this.social = true
      this.consentGiven()
    }
  }
}
</script>

<style>
.overlay {
  position: fixed;
  bottom: 8%;
  left: 5%;
  right: 5%;
  width: 90%;
}

.cookie-consent {
  bottom: 20px;
  background-color: var(--ivory);
  color: black;
  padding: 1em;
  border: 2px solid var(--dark-junge-green);
  border-radius: 10px;
}
</style>
