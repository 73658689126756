<template>
  <StandardLayout :padding="false">
    <template slot="main">

      <!-- Summer -->
      <div id="first_image"
           v-if="isSummerSeason"
           class="position-relative">
        <VideoBackground
            src="videos/home_desktop.mp4"
            style="height: 300px;"
            poster="videos/home_poster.png"
            :sources="[{src: 'videos/home_desktop.mp4', res: 900, autoplay: true},
                         {src: 'videos/home_mobile.mp4', res: 638, autoplay: true}]">
          <div class="position-absolute px-5" style="bottom: 5%;">
            <div class="col-12 col-md-8 offset-md-2">
              <cld-image public-id="logo_unicolor"
                         class="img-fluid"
                         width="1145"
                         height="209"/>
              <div class="mega-font text-center">
                <h1 style="font-size: 1.7rem;">Das exklusive Saunaboot auf dem Störmthaler See</h1><br/>
                <c-t-a-button class="mt-2"
                              route="editReservation">
                  Jetzt Buchen
                </c-t-a-button>
              </div>
            </div>
          </div>
        </VideoBackground>
      </div>
      <!-- Summer -->
      <!-- Winter -->
      <div v-else
           id="first_image"
           class="position-relative d-block text-center">
        <cloudinary-image
            class="img-fluid"
            public-id="home/see_sonnenuntergang_2"
            width="800"
            height="533"
            crop="fill">
          <cld-transformation quality="auto:low"/>
        </cloudinary-image>
        <div class="position-absolute px-5" style="top: 27%;">
          <div class="col-12 col-md-8 offset-md-2">
            <cld-image public-id="logo_unicolor"
                       class="img-fluid"
                       width="1145"
                       height="209"/>
            <div class="mega-font mega-font-small text-center">
              Das exklusive Saunaboot auf dem Störmthaler See<br/>
              <c-t-a-button class="mt-2"
                            route="editReservation">
                Jetzt Buchen
              </c-t-a-button>
            </div>
          </div>
        </div>
      </div>
      <!-- / Winter -->

      <div class="text-block">
        <div>Exklusiv gechartertes Saunaboot für bis zu 10 Personen</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>Kaminsauna mit <a href="https://brennholz-werner.de/">Brennholz aus regionalem Handel</a></div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>leiser, umweltschonender Elektroantrieb</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>gemütliche Saunalounge mit Panoramafenstern</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>Relax-Dachterrasse</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>Kühlschrank mit Getränken zu fairen Preisen</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>Neuseenland Leipzig mal anders erleben</div>
      </div>

      <voucher-panel/>

      <div class="text-block">
        <h2>Erlebe eine unvergessliche Auszeit!</h2>
        <p>Die schwimmende Sauna garantiert Erholung und Abenteuer mitten in der ungestörten Natur.</p>
      </div>

      <div class="d-flex justify-content-center align-items-center" style="flex-wrap: wrap;">
        <div>
          <a href="https://www.heyhobby.de/highlights/saunen/leipzig/"
             aria-label="heyHobby: Leipzig entdecken: 14 Top-Saunen und Thermen für eine Auszeit"
             title="Leipzig entdecken: 14 Top-Saunen und Thermen für eine Auszeit">
            <cloudinary-image
                public-id="home/heyhobby_"
                height="110"
                width="110"
                class="pl-2"/>
          </a>
        </div>
        <div>
          <a href="https://www.kayak.de/Leipzig.11845.guide"
             aria-label="KAYAK Leipzig Travel Guide für weitere Ideen."
             title="KAYAK: Leipzig Travel Guide für weitere Ideen.">
            <cloudinary-image
                public-id="home/kayak"
                height="87"
                width="110"
                class="pl-2"/>
          </a>
        </div>
        <GoogleCustomerReviewsBadge class="mx-1"/>
      </div>

      <review-carousel/>


      <div class="text-block">
        <p>Buche das Saunaboot für Dich und Deine Freunde. </p>
      </div>

      <div class="position-relative">
        <cloudinary-image
            public-id="home/farbenspiel_abend_2"
            class="img-fluid"
            width="992"
            height="558"
            crop="fill"/>
        <div class="position-absolute w-100" style="bottom: calc(20%);">
          <div class="d-block text-center">
            <div class="mega-font mb-3">Wellness im Leipziger Neuseenland</div>
            <c-t-a-button class="mt-2"
                          route="productsAndPrices">
              Unsere Erlebnisse
            </c-t-a-button>
          </div>
        </div>
      </div>

      <div class="text-block">
        <p>Lass Dich geräuschlos in eine versteckte Bucht gleiten, in der die Sonnenstrahlen das Wasser funkeln
          lassen. </p>
        <p>Die Wärme der Sauna und wohltuende Düfte von wilden Pflanzen und zarten Blüten verwöhnen Deine Sinne
          bei jedem Aufguss und lassen den Alltag in weite Ferne rücken.</p>
      </div>

      <div class="position-relative">
        <cloudinary-image
            public-id="home/terasse_sitz_sonne_2"
            class="img-fluid"
            height="558"
            width="992"/>
        <div class="position-absolute w-100" style="bottom: calc(20%);">
          <div class="d-block text-center">
            <div class="mega-font mb-3">Panoramaplatz an der Sonne</div>
            <c-t-a-button class="mt-2"
                          route="editReservation">
              Jetzt Buchen
            </c-t-a-button>
          </div>
        </div>
      </div>

      <div class="text-block">
        <p>
          Mach es Dir in der Sauna-Lounge zu Deiner Lieblingsmusik gemütlich oder genieße die Ruhe & Natur auf
          der
          Relax-Dachterrasse.
        </p>
      </div>

      <div class="position-relative">
        <cloudinary-image
            class="img-fluid"
            :public-id="isSummerSeason ? 'home/sprung_bargi' : 'home/leiter'"
            :height="isSummerSeason ? '558' : '992'"
            width="992"/>
        <div class="text-center w-100 position-absolute" :style="{top: `${isSummerSeason ? 40 : 55}%`}">
          <p class="mega-font">Beste Abkühlung der Welt</p>
          <c-t-a-button class="mt-2"
                        route="editReservation">
            Jetzt Buchen
          </c-t-a-button>
        </div>
      </div>
      <div class="text-block">
        <p>Ein Sprung ins kühle Wasser sorgt für den himmlisch leichten Sauna-Effekt nach dem Saunagang.</p>
      </div>

      <div class="position-relative text-center">
        <cloudinary-image
            class="img-fluid"
            :public-id="isSummerSeason ? 'home/jungs_bier' : 'home/sauna_innen'"
            :height="isSummerSeason? '300' : '800'"
            width="auto"/>
        <div class="text-center w-100 position-absolute" style="bottom: 5%;">
          <p class="mega-font"></p>
          <c-t-a-button class="mt-2"
                        route="gallery">
            Zur Galerie
          </c-t-a-button>
        </div>
      </div>
      <div class="text-block">
        <p>Nach dem Saunieren dann als Belohnung: Bei einem kühlen Bier oder einer Flasche Sekt zusammen
          die untergehende Sonne genießen, während sich das Abendlicht auf dem Wasser spiegelt.</p>
        <p>Die perfekte Art Deinen SPABoot-Tag im Paradies um die Ecke Revue passieren zu lassen.</p>
      </div>

      <div class="position-relative">
        <cloudinary-image
            class="img-fluid"
            public-id="home/see_sonnenuntergang_2"
            height="558"
            width="992"/>
        <div class="position-absolute text-center"
             style="bottom: 15%;">
          <div class="col-8 offset-2 col-md-6 offset-md-3">
            <cld-image public-id="logo_unicolor"
                       width="1145"
                       height="209"
                       responsive="true"/>
            <c-t-a-button route="editReservation">
              Jetzt Buchen
            </c-t-a-button>
          </div>
        </div>
      </div>

      <div class="text-block">
        <h2>Unser Konzept: Umweltbewusst, bio & regional</h2>
        <div>Umweltfreundliche Fortbewegung durch Elektroantrieb</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>Bio-Trenn-Toilette an Bord</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>Naturkosmetik aus Ressourcen des Leipziger Umland</div>
        <cld-image public-id="wave" height="12" width="75" class="my-1"/>
        <div>nachhaltige Energie aus Solaranlage auf dem Boot</div>
      </div>

      <LeaderBanner style="margin: 2%;"/>

    </template>
  </StandardLayout>
</template>

<script>
import StandardLayout from "@/layouts/StandardLayout";
import LeaderBanner from "@/components/LeaderBanner"
import VoucherPanel from "@/components/home/VoucherPanel";
import CloudinaryImage from "@/components/generic/CloudinaryImage";
import CTAButton from "@/components/generic/CTAButton";
import VideoBackground from 'vue-responsive-video-background-player'
import ReviewCarousel from "@/components/reviews/ReviewCarousel";
import {mapGetters} from "vuex";
import GoogleCustomerReviewsBadge from "../components/reviews/GoogleCustomerReviewsBadge.vue";

export default {
  components: {
    GoogleCustomerReviewsBadge,
    ReviewCarousel,
    CTAButton, VoucherPanel, StandardLayout, LeaderBanner, CloudinaryImage, VideoBackground
  },
  computed: {
    ...mapGetters(['isSummerSeason'])
  }
}
</script>

<style lang="scss">

.restrict-width {
  display: block;
  margin: 0 auto;
  max-width: 1000px;
  color: #5A738A;
}

.hero-image {
  display: block;
  position: relative;
  min-width: 100%;
  color: white;
  text-shadow: 0 0 4px black;
}

.text-block {
  position: relative;
  display: block;
  text-align: center;
  margin: 1.2rem 0 1.2rem 0;
  width: 90%;
  left: 5%;
}

.mega-font {
  color: white;
  font-size: xx-large;
  font-weight: bolder;
  text-shadow: 0 0 2px black;
  line-height: 110%;
}

.mega-font-small {
  padding-top: 1.3rem;
}

@media screen and (max-width: 767px) {
  .mega-font-small {
    font-size: 1rem;
    padding-top: 0.3rem;
  }
}

</style>
