<template>
  <button class="btn btn-cta font-weight-bold"
          @click="$router.push({name: route})">
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    route: String
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/custom';

.btn-cta {
  /*
  background-color: #93032E;
   */
  background-color: #C3423F;
  color: $ivory;
  font-weight: bolder;
}

.btn-cta:hover {
  color: $ivory;
  border: 1px solid $ivory;
}
</style>
