<template>
  <div class="overlay bg-dark"
       v-if="loading">
    <div class="content h1 text-center">
      <span class="text-white">
        {{ loadingMessage }}
      <font-awesome-icon icon="spinner" class="fa-spin"/>
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['loading', 'loadingMessage'])
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  z-index: 9999;
  display: table;
  top: 0;
  left: 0;
  table-layout: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 75%;
}

.content {
  display: table-cell;
  vertical-align: middle;
}
</style>
